@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.css";

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
  --font-default: "Raleway-Medium", Arial, Helvetica, sans-serif;
  --font-regular: "Raleway-Regular", Arial, Helvetica, sans-serif;
  --font-bold: "Raleway-Bold", Arial, Helvetica, sans-serif;
  --primary-color: #017bac;
}

body {
  color: rgb(var(--foreground-rgb));
  margin: 0;
  font-family: var(--font-default);
  scroll-behavior: smooth !important;
  line-height: normal;
  background-color: black;
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

h2 {
  font-size: 32px;
  line-height: 1.2;
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.fp-watermark {
  display: none;
}

.slide-scrollable {
  margin-top: 65px;
  overflow-y: auto;
}

/* .fp-overflow::-webkit-scrollbar {
  background-color: transparent;
  width: 9px;
}
.fp-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}
.fp-overflow::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  border: 4px solid transparent;
} */

.bold,
b {
  font-family: var(--font-bold);
}

.vertical-timeline--two-columns
  .vertical-timeline-element.vertical-timeline-element--left
  .vertical-timeline-element-content
  .vertical-timeline-element-date,
.vertical-timeline--two-columns
  .vertical-timeline-element:nth-child(odd):not(.vertical-timeline-element--right)
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  text-align: left;
}

strong {
  color: var(--primary-color);
  font-family: var(--font-bold);
}

.cursorTyping {
  font-size: 40px;
  margin-bottom: 8px;
  font-family: var(--font-bold);
  color: var(--primary-color);
}

html {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  overflow-y: auto;
}

html::-webkit-scrollbar {
  display: none;
}

.section-snap {
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  overflow-y: auto;
}
