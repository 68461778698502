@font-face {
  font-family: "Raleway-Medium";
  src: url("../../public/fonts/Raleway-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway-Regular";
  src: url("../../public/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../../public/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway-Light";
  src: url("../../public/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
