.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: rgba(250, 250, 250);
  border-radius: 3px;
}

.swiper-pagination-bullets {
  position: relative;
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed !important;
}
