:root {
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
}

.card-wrapper {
  margin-bottom: 40px;
}

.card {
  display: grid;
  place-items: center;
  /* width: 80vw; */
  /* max-width: 21.875rem; */
  min-height: 18.125rem;

  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);

  width: 100%;
  /* height: auto; */
}

.card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card__background {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.card__content {
  --flow-space: 0.9375rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
  height: 100%;
  /* padding: 12% 1.25rem 11rem; */
  padding: 5% 1.25rem 0rem;
  /* margin-bottom: -2px; */
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0.2) 0%,
    hsla(0, 0%, 0%, 0.7) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.card__content--container {
  --flow-space: 1.25rem;
}

.card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 26px;
}

.card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--primary-color);
}

.card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content;
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--primary-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .card__content {
    /* transform: translateY(62%); */
    transform: translateY(80%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
    width: 100%;
  }

  @keyframes blurAnimation {
    to {
      filter: blur(5px); /* Set the blur amount */
    }
  }

  @keyframes blurAnimation2 {
    from {
      filter: blur(5px);
    }
    to {
      filter: blur(0px); /* Set the blur amount */
    }
  }

  .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .card__background {
    transition: transform 500ms ease-in;
    animation: blurAnimation2 2s forwards;
  }

  .card:hover .card__background {
    animation: blurAnimation 2s forwards;
  }

  .card__content--container > :not(.card__title),
  .card__button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  /* .card:hover,
  .card:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  } */

  .card:hover .card__content,
  .card:focus-within .card__content {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .card:focus-within .card__content {
    transition-duration: 0ms;
  }

  .card:hover .card__background,
  .card:focus-within .card__background {
    transform: scale(1);
  }

  .card:hover .card__content--container > :not(.card__title),
  .card:hover .card__button,
  .card:focus-within .card__content--container > :not(.card__title),
  .card:focus-within .card__button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .card:hover .card__title::after,
  .card:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}
